// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Page, IconList, FooterItem, FooterBlock, ContactRequest, ContactBlock, ServiceItem, ServiceBlock, ImageList, SkillBlock, MainBlock, AboutBlock, Website, NavbarData, Test } = initSchema(schema);

export {
  Page,
  IconList,
  FooterItem,
  FooterBlock,
  ContactRequest,
  ContactBlock,
  ServiceItem,
  ServiceBlock,
  ImageList,
  SkillBlock,
  MainBlock,
  AboutBlock,
  Website,
  NavbarData,
  Test
};