import "./src/global.css"
import React from "react"
import { Amplify } from "aws-amplify"
import awsconfig from "./src/aws-exports"
import { ThemeProvider } from "@aws-amplify/ui-react"
import { studioTheme } from "./src/ui-components"
import "@aws-amplify/ui-react/styles.css"

Amplify.configure(awsconfig)

const mainFont = "Raleway, sans-serif"

const myTheme = {
  ...studioTheme,
  name: "my-theme",
  tokens: {
    ...studioTheme.tokens,
    fonts: {
      ...studioTheme.tokens.fonts,
      default: {
        variable: { value: mainFont },
        static: { value: mainFont },
      },
    },
  },
}

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={myTheme}>{element}</ThemeProvider>
)
